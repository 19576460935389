import { useState, useEffect, useMemo } from 'react'

import ZundamonOn from '../../Assets/Img/Zundamon/zundamon.gif'
import ZundamonOff from '../../Assets/Img/Zundamon/zundamon_off.png'
import Background from '../../Assets/Img/Zundamon/background.jpg'
import Voice from '../../Assets/Sounds/Zundamon/VoiceFX.mp3'

import axios from 'axios'   

interface Message {
    message: string,
    assistant: boolean
}

const defaultMessage: Message = {
    message: 'Hello!! I\'m Zundamon',
    assistant: true
}

const defaultAnimatedState = {
    state: false,
    message: '',
}

const defaultErrorState = {
    value: false,
    message: ''
}

const ZundamonChat = () => {
    const [messages, setMessages] = useState<Message[]>([defaultMessage])
    const [textInput, setInputText] = useState('')
    const [errorState, setErrorState] = useState(defaultErrorState)

    const audio = useMemo(() => {
        const audioInstance = new Audio(Voice);
        audioInstance.loop = true;
        audioInstance.playbackRate = 1.5;
        audioInstance.volume = 1;
        return audioInstance;
    }, []);

    type feelings = 'happy' | 'angry' | 'scared' | 'happy' | 'thinking'

    interface AIResponse {
        ai_response: string;
        feeling: feelings;
    }

    const [animatedMessage, setAnimatedMessage] = useState(defaultAnimatedState)

    const addAnimatedMessage = (msg: string) => {
        audio.play();
        setAnimatedMessage({ state: true, message: '' })
        let index = 0

        if (!msg) return

        const typingInterval = setInterval(() => {
            if (index < msg.length) {
                setAnimatedMessage(prev => ({
                    ...prev,
                    message: msg.substring(0, index)
                }))

                if (msg[index] === ''){
                    audio.pause();    
                } else if (msg[index] !== '' && audio.paused){
                    audio.play();
                }

                index++
            } else {
                audio.pause();
                clearInterval(typingInterval)
                setAnimatedMessage(defaultAnimatedState)
                addMessage(msg, true)
            }
        }, 25) 

        audio.pause();
    }
    
    const addMessage = (msg: string, assistant: boolean) => {
        setMessages((prev: Message[]) => {
            const newMessage = {
                message: msg,
                assistant: assistant
            }
            const newState: Message[] = [newMessage, ...prev]
            return newState
        })
    }

    useEffect(() => {
        return () => {
            audio.currentTime = 0
        }
    }, [audio])

    const ZUNDAMON_API_URL = process.env.REACT_APP_ZUNDAMON_API_URL

    const sendMessage = async () => {
        if (textInput.length === 0 || animatedMessage.state) return
        const newMessage = {
            message: textInput,
            assistant: false
        }
        addMessage(textInput, false)
        setInputText('');

        let sendMessages = [newMessage, ...messages].slice(-20)
        sendMessages = sendMessages.reverse();

        axios({
            method: 'POST',
            url: ZUNDAMON_API_URL,
            headers: {
                'Content-Type': 'application/json',
                // 'Timezone': timezone,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: {
                messages: sendMessages
            }
        }).then((res) => {
            if (res.status === 200) {
                const data: AIResponse = res.data;
                addAnimatedMessage(data.ai_response)
                console.log(data)
            } else if (res.status === 429) {
                addAnimatedMessage(res.data.response)
            }
        }).catch((err) => {
            console.error(err)
            setErrorState({
                value: true,
                message: '❌ Error in sending a message, try again later!',
            })
            setTimeout(() => {
               setErrorState(defaultErrorState) 
            }, 4000)
        })
    }

    return (
        <div className="w-screen h-screen bg-zinc-900"
            style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >            
            <div className="left-0 flex flex-col w-full h-full relative">
                <div className="w-full md:w-3/5 h-4/5 flex flex-col-reverse gap-4 p-4 overflow-scroll transition-all ">
                    {
                        animatedMessage.state ? <ChatBubble message={animatedMessage.message} assistant={true}/> : null
                    }
                    {
                        messages.map((msg: Message, index: number) => (
                            <ChatBubble message={msg.message} assistant={msg.assistant}/>
                        ))
                    }
                </div>
                {
                    errorState.value ? <p className='px-5 font-bold text-red-500'>{ errorState.message }</p> : null
                }
                <img src={animatedMessage.state ? ZundamonOn : ZundamonOff} className='md:absolute md:right-0 md:bottom-0 w-44 md:w-2/5 h-fit self-end -mb-4 md:m-0' alt="Zundamon speaking pixel art avatar"></img>
                <div className="w-full md:w-3/5 h-1/5 p-4 relative">
                    <textarea 
                        style={{ resize: 'none' }}
                        className="w-full h-full bg-zinc-200 rounded-lg text-black text-xl p-4 border-emerald-700 border-4"
                        value={textInput}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInputText(e.target.value)}
                        onKeyUp={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') sendMessage();
                        }}
                    ></textarea>
                    <button onClick={sendMessage} className="absolute bottom-6 right-6 w-12 h-12 bg-emerald-700 rounded-full flex justify-center items-center">
                        <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="undefined">
                            <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

const ChatBubble = ({ message, assistant }: Message) => {
    return (
        <div className={`w-full h-fit text-xl p-4 rounded-xl 
            ${ assistant ?  'bg-emerald-600 text-white ' : 'bg-zinc-200 text-black'}`}>
            <p className='w-full break-words whitespace-pre-wrap'>{ message }</p>
        </div>
    )
}

export default ZundamonChat;