import { useContext, useState, useEffect, ReactNode } from "react";
import { createContext } from "react";
import { useLocation } from 'react-router-dom';
import axios from 'axios'

interface LoginContextType {
    login: boolean;
};

export const LoginContext = createContext<LoginContextType>(
    {} as LoginContextType
);

export const LoginProvider = ({ children } : { children: ReactNode }) => {
    const [login, setLogin] = useState(false);
    const location = useLocation();

    const getQueryParams = (search: string) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const refId = queryParams.get('refId');

    const loginCall = () => {
        const LOGIN_URL = process.env.REACT_APP_LOGIN_API_URL;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        axios({
          method: 'GET',
          url: LOGIN_URL,
          headers: {
            'Content-Type': 'application/json',
            'Timezone': timezone,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          data: {
            refId: refId
          },
        }).then((res) => {
          if (res.status === 201) {
            localStorage.setItem('token', res.data.token)
          }
          setLogin(true)
        }).catch((err) => {
          console.log(err)
          setLogin(false)
        })
    }

    useEffect(() => {
      loginCall()
    }, []);

    return (
        <LoginContext.Provider value={{login}}>
          { children }	
        </LoginContext.Provider>
    )
}

export const useLogin = () => {
    const context = useContext(LoginContext);

    if (!context) {
        throw new Error('useLogin must be within LoginProvider');
    }

    return context
}