import { useTheme } from "../../Context/Themes";
import NervessLogo from "../../Assets/Img/NervessLogo/NervessCatWhiteLogo.png"
import { useEffect, useState } from "react";

import Marquee from 'react-fast-marquee';
import '../../Assets/Styles/CounterClock.css'
import { useLanguage } from "../../Context/LanguageContext";

const FloatingIcon = () => {
    const { theme } = useTheme()
    const { lang } = useLanguage();
    const [isOpen, setIsOpen] = useState(false)

    const switchOpen = () => {
        setIsOpen((prev) => (!prev))
    }

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true)
            setTimeout(() => {
                setIsOpen(false)
            }, 40000)
        }, 8500)
    }, [])

    return(
        <div className="h-8 w-full fixed z-50 right-0 top-0 p-4 flex-col">
            <div className="w-full h-fit grid grid-cols-6 sm:grid-cols-8 lg:grid-cols-10 2xl:grid-cols-12 grid-rows-1 space-x-4 place-items-center">
                <div className={`w-full h-8 col-span-5 sm:col-span-7 lg:col-span-9 2xl:col-span-11 bg-black rounded-full transition-all duration-500 overflow-clip origin-right ${ isOpen ? 'opacity-100' : 'opacity-0' }`}>
                    <div className={`mt-0.5 clock ${ theme.secondary.textBold }`}>
                        <Marquee>
                            <div className="flex gap-4">
                            {
                                lang.news.map((news: string) => {
                                    return (
                                        <p className={`font-bold text-white`}>{ news }</p>
                                    )
                                })
                            }
                            </div>
                        </Marquee>
                    </div>
                </div>
                <div className={`w-8 h-8 rounded-full ${ theme.primary.bgColor }`}>
                    <img onClick={switchOpen} alt="nervesscat logo"
                    className={`w-8 h-8 cursor-pointer rounded-full border-2 ${theme.primary.borderColor} ${ isOpen ? 'animate-spin' : ''}`} 
                    src={NervessLogo}></img>
                </div>
            </div>
        </div>
    )
}

export default FloatingIcon;